import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  value: string;
  private months = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'Aug',
    9: 'Sept',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec'
  }

  constructor(private toastController: ToastController) { }

  async showToast(className: string, message: string, duration: number) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      cssClass: className
    });
    toast.present();
  }

  pad(n) {
    if (n < 10){
      return '0'+n;
    }
    return n;
  }

  setValue(data){
    this.value = data;
  }

  getValue(){
    return this.value;
  }



  getToday(){
    let date = new Date();
    return date.getFullYear()+"-"+this.pad(date.getMonth()+1)+"-"+this.pad(date.getDate())
  }

  getTime(d) {
    let date = new Date(d);
    let hours = date.getHours();
    let minutes = ""+date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = parseInt(minutes) < 10 ? '0'+minutes : minutes;
    var strTime = this.pad(hours) + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  getCurrentDateTime(){
    let date = new Date();
    return date.getFullYear()+"-"+this.pad(date.getMonth()+1)+"-"+this.pad(date.getDate())+" "+this.pad(date.getHours())+":"+this.pad(date.getMinutes())+":"+this.pad(date.getSeconds());
  }

  getNoDays(from, to) {
    var date1 = new Date(from.substring(0, 10));
    var date2 = new Date(to.substring(0, 10));
    var diff = date2.getTime() - date1.getTime();
    return (diff  / (1000 * 3600 * 24)) + 1;
  }

  formatDate(date){
    let from_date = new Date(date);
    return from_date.getDate() + ' ' + this.months[from_date.getMonth() + 1] + ' ' + from_date.getFullYear();
  }

  waitlistLimit(maxEnrollments: number) {
    return maxEnrollments + Math.round(0.2 * maxEnrollments);
  }

}
