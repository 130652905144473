import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UtilsService } from './utils.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private utils: UtilsService, private session: SessionService, private router: Router) {  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let token = this.session.getToken();
    if (token == null || token.length < 128) {
      this.session.destroySession();
      this.utils.showToast('toast-info', 'You were logged out since your session expired. Please login again.', 4000);
      this.router.navigateByUrl('/login');
      return false;
    }
    return true;
  }

}
