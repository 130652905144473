import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from './session.service';
import { AppVersion } from '@ionic-native/app-version/ngx';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  public apiURL = 'https://sandhyavandanam.org/kcdt_gayatri';
  public profileURL = 'https://sandhyavandanam.org/administration/pages/';

  constructor(private http: HttpClient, private session: SessionService, private appVersion: AppVersion) { }

  getHeaders(){
    let token = this.session.getToken();
    let httpOptions = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Bearer '+token,
      'Member': this.session.getMemberID()
    });    
    return httpOptions;
  }

  updateVersion(){
    this.appVersion.getVersionNumber().then(versionNo => {
      console.log(versionNo);
      this.http.post(this.apiURL+'/updateVersion.php', {
        memberId: this.session.getMemberID(),
        version: versionNo
      }, { headers: this.getHeaders() }).subscribe(
        res => {
          console.log('Updated Version', res);
        }
      );
    }).catch(err => {
      console.log(err);
    });
  }

  login(username: number, password: string, device_id: any) {
    return this.http.post(this.apiURL+'/login.php', {
      username: username,
      password: password,
      device: device_id
    });
  }

  getSibiramDetails(memberId:string, date: string){
    return this.http.get(this.apiURL+'/sibiramDetails.php?memberId='+memberId+'&date='+date, { headers: this.getHeaders() });
  }

  getMeetingDetails(memberId:string, date:string){
    return this.http.get(this.apiURL+'/meetingDetails.php?memberId='+memberId+"&date="+date, { headers: this.getHeaders() });
  }

  getRevisionDetails(memberId:string, date:string){
    return this.http.get(this.apiURL+'/reviewSessionDetails.php?memberId='+memberId+"&date="+date, { headers: this.getHeaders() });
  }

  getJapamDetails(memberId:string, date:string){
    return this.http.get(this.apiURL+'/gayatriJapamDetails.php?memberId='+memberId+"&date="+date, { headers: this.getHeaders() });
  }

  setUserData(data: any){
    return this.http.post(this.apiURL+'/setUserData.php', data, {
      headers: this.getHeaders()
    });
  }

  getEvent(id: number, getParticipants: boolean = false) {
    return this.http.get(this.apiURL+'/getEvent.php?eventId='+id+'&memberId='+this.session.getMemberID()+'&getParticipants='+getParticipants, {
      headers: this.getHeaders()
    });
  }

  getEnrolledEvents(date: string) {
    return this.http.get(this.apiURL+'/getEnrolledEvents.php?memberId='+this.session.getMemberID()+'&date='+date, {
      headers: this.getHeaders()
    });
  }

  getUserData() {
    return this.http.get(this.apiURL+'/getUserData.php?memberId='+this.session.getMemberID()+'&device='+this.session.getDeviceID(), {
      headers: this.getHeaders()
    });
  }

  sendOTP(contact: string, email: string){
    return this.http.post(this.apiURL+'/requestOTP.php',{
      contact: contact,
      email: email      
    },{});
  }

  getMemberId(mobile: string, email: string){

    if(mobile != null)
    {
    return this.http.get(this.apiURL+'/searchMember.php?mobile='+mobile, {
      headers: this.getHeaders()
    });
    }
    else
    {
      return this.http.get(this.apiURL+'/searchMember.php?email='+email, {
        headers: this.getHeaders()
      });
    }
  }



  

  manageAttendanceMark(eventId: number, attendance: number) {
    return this.http.post(this.apiURL+'/manageAttendance.php', {
      eventId: eventId,
      attendance: attendance
    }, { headers: this.getHeaders() });
  }

  markAttended(eventID: string, memberId: string, date:string, guests: any, type: string, wife: any){
    return this.http.post(this.apiURL+'/markAttendance.php', {
      eventID: eventID,
      memberID: memberId,
      date: date,
      guests: guests,
      type: type,
      wife: wife
    }, { headers: this.getHeaders() });
  }

  getSankalpam(date: string){
    return this.http.get(this.apiURL+'/getSankalpam.php?date='+date, {
      headers: this.getHeaders()
    });
  }

  enrollInEvent(eventId: number, memberId: number) {    
    return this.http.post(this.apiURL+'/enrollInEvent.php', {
      eventId: eventId,
      memberId: memberId
    }, { headers: this.getHeaders() });
  }

  reserveSlot(eventId: number, memberId: number) {
    return this.http.post(this.apiURL+'/reservePlace.php', {
      eventId: eventId,
      memberId: memberId
    }, { headers: this.getHeaders() });
  }  

  cancelSlot(reservationId: number) {
    return this.http.post(this.apiURL+'/cancelSlot.php', {
      reservationId: reservationId,
    }, { headers: this.getHeaders() });
  }

  addCounts(eventId: string, memberId: string, enrollmentId: string, japam: string, tarpanam: string, start: string, updated_by: string){
    return this.http.post(this.apiURL+'/addCounts.php',{
      eventId: eventId,
      enrollmentId: enrollmentId,
      memberId: memberId,
      japam: japam,
      tarpanam: tarpanam,
      start: start,
      updated_by: updated_by
    },{ headers: this.getHeaders() });
  }

  getAttendanceStats(eventId: number) {
    return this.http.get(this.apiURL+'/getAttendanceStats.php?eventId='+eventId, { headers: this.getHeaders() });
  }

  reserveSpot(eventId, memberId) {
    return this.http.post(this.apiURL+'/reservePlace.php', {
      eventId: eventId,
      memberId: memberId
    }, { headers: this.getHeaders() });
  }

  sendLinkToEmail(eventId, name, email) {
    return this.http.post(this.apiURL+'/sendLinkToEmail.php', {
      Email: email,
      Name: name,
      EventID: eventId
    }, { headers: this.getHeaders() });
  }

  removeUnattended(eventId) {
    return this.http.post(this.apiURL+'/removeUnattended.php', {
      eventId: eventId
    }, { headers: this.getHeaders() });
  }

  getNewsItems(date: string) {
    return this.http.get(this.apiURL+'/getNewsItems.php?memberId='+this.session.getMemberID()+"&date="+date, { headers: this.getHeaders() });
  }

  addSankalpam(date, samvatsaram, samvatsaramEn, aayanam, aayanamEn, ruthuvu, ruthuvuEn, maasam, maasamEn, paksham, pakshamEn, tidhi, tidhiEn, vaaram, vaaramEn, sunrise, sunset) {
    return this.http.post(this.apiURL+'/addSankalpam.php', {
      Samvatsaram: samvatsaram,
      SamvatsaramEn: samvatsaramEn,
      Aayanam: aayanam,
      AayanamEn: aayanamEn,
      Ruthuvu: ruthuvu,
      RuthuvuEn: ruthuvuEn,
      Maasam: maasam,
      MaasamEn: maasamEn,
      Paksham: paksham,
      PakshamEn: pakshamEn,
      Tidhi: tidhi,
      TidhiEn: tidhiEn,
      Vaaram: vaaram,
      VaaramEn: vaaramEn,
      Sunrise: sunrise,
      Sunset: sunset,
      Date: date
    }, { headers: this.getHeaders() });
  }

  getFeedbackQuestions(enrollmentId: number) {
    return this.http.get(this.apiURL+'/getFeedbackQuestions.php?enrollmentID='+enrollmentId, 
    { headers: this.getHeaders() });
  }

  submitEventFeedback(enrollmentID: number, questions: any) {
    return this.http.post(this.apiURL+'/submitEventFeedback.php', {
      enrollmentID: enrollmentID,
      feedback: questions
    }, { headers: this.getHeaders() });
  }

  getAllFAQs(selectAll: string) {
    return this.http.get(this.apiURL+'/getFrequentlyAskedQuestions.php?selectAll='+selectAll, 
      { headers: this.getHeaders() });
  }

  getFAQAnswer(questionID: String) {
    return this.http.get(this.apiURL+'/getFAQAnswer.php?questionID='+questionID, { headers: this.getHeaders() });
  }

  changePassword(username: string, password: string){
    return this.http.post(this.apiURL+'/changePass.php',{
      username: username,
      password: password
    }, {});
  }

  changeMeetingLink(meetingLink: string, eventId: number) {
    return this.http.post(this.apiURL+'/changeMeetingLink.php', {
      meetingLink: meetingLink,
      eventId: eventId
    }, { headers: this.getHeaders() });
  }

  addPushToken(token: string, memberId: string, platform: string) {
    return this.http.post(this.apiURL+'/addPushToken.php', {
      token: token,
      memberId: memberId,
      platform: platform
    }, { headers: this.getHeaders() });
  }
  
  signOut(push: string, memberId: string, device_id: string) {
    return this.http.post(this.apiURL+'/signOut.php', {
      push: push,
      memberId: memberId,
      device_id: device_id
    }, { headers: this.getHeaders() });
  }

  getActivityDetails(memberId:string, date:string){
    return this.http.get(this.apiURL+'/activityDetails.php?memberId='+memberId+"&date="+date, 
    { headers: this.getHeaders() });
  }

  getRegistrationDetails(date:string){
    return this.http.get(this.apiURL+'/getRegistrations.php?date='+date, 
    { headers: this.getHeaders() });
  }
  
  getRegisteredProfile(appID:string){
    return this.http.get(this.apiURL+'/getRegisteredProfile.php?appID='+appID, 
    { headers: this.getHeaders() });
  }

  updateRegistration(application: any, status: string, updateBy: string, eventID: any) {
    application.Status = status;
    let payload = {
      application: application,
      updatedBy: updateBy,
      eventID: eventID
    };
    console.log(payload);
    return this.http.post(this.apiURL+'/handleRegistrations.php', payload, { headers: this.getHeaders() });
  }
  
  getActiveSibirams(date: string){
    return this.http.get(this.apiURL+'/getActiveSibirams.php?&date='+date, 
    { headers: this.getHeaders() });
  }

  getLeadershipStats(memberId: string) {
    return this.http.get(this.apiURL+'/getLeadershipStats.php?memberId='+memberId, { headers: this.getHeaders() });
  }

  getLatestVersions() {
    return this.http.get(this.apiURL+'/getLatestVersions.php');
  }

  addMembershipPoints(memberId: string, reason: string, points: number, updatedBy: string) {
    return this.http.post(this.apiURL+'/addMembershipPoints.php', {
      memberId: memberId,
      reason: reason,
      points: points,
      updatedBy: updatedBy
    }, { headers: this.getHeaders() });
  }

  searchMember(memberId: string, name: string, mobile: string, email: string) {
    return this.http.get(this.apiURL+'/searchMember.php?memberId='+memberId+
      '&name='+name+'&mobile='+mobile+'&email='+email, { headers: this.getHeaders() });
  }

  createEvent(data: any){
    return this.http.post(this.apiURL+'/createEvent.php', data, {
      headers: this.getHeaders()
    });
  }
  
  removeSrisevak(memberId: number) {
    return this.http.post(this.apiURL+'/removeSrisevak.php', {
      memberId: memberId
    }, { headers: this.getHeaders() });
  }

  getSrisevaksList() {
    return this.http.get(this.apiURL+'/getSrisevaksList.php', { headers: this.getHeaders() });
  }
  
  ResetPassword(memberId: string, oldPassword: string, newPassword: string){
    return this.http.post(this.apiURL+'/updatePass.php',{
      memberId: memberId,
      oldPass: oldPassword,
      newPass: newPassword 
    }, { headers: this.getHeaders() });
  }

  getGroup(groupId: number) {
    return this.http.get(this.apiURL+'/getGroup.php?groupId='+groupId, { headers: this.getHeaders() });
  }

  addGroupMember(groupId: number, memberId: number) {
    return this.http.post(this.apiURL+'/addGroupMember.php', {
      groupId: groupId,
      memberId: memberId
    }, { headers: this.getHeaders() });
  }

  dropGroupMember(groupId: number, memberId: number) {
    return this.http.post(this.apiURL+'/dropGroupMember.php', {
      groupId: groupId,
      memberId: memberId
    }, { headers: this.getHeaders() });
  }

  makeAdmin(groupId: number, memberId: number) {
    return this.http.post(this.apiURL+'/makeGroupAdmin.php', {
      groupId: groupId,
      memberId: memberId
    }, { headers: this.getHeaders() });
  }

  makeSrisevak(memberId: string) {
    return this.http.post(this.apiURL+'/makeSrisevak.php', {      
      memberId: memberId
    }, { headers: this.getHeaders() });
  }

  makeSrisevakWithPreferences(memberId: string, tasks: any) {
    return this.http.post(this.apiURL+'/makeSrisevakWithPreferences.php', {      
      memberId: memberId,
      tasks: tasks
    }, { headers: this.getHeaders() });
  }

  getSrisevakGuidelines() {
    return this.http.get(this.apiURL+'/getSrisevakGuidelines.php');
  }

  getParticipantsList(eventID: number, memberID: number){
    return this.http.get(this.apiURL+'/getParticipantList.php?eventID='+eventID+'&memberID='+memberID, 
    { headers: this.getHeaders() });
  }

  removeParticipant(eventID: number, memberID: number, poc: number) {
    return this.http.post(this.apiURL+'/removeParticipant.php', {
      eventID: eventID,
      memberID: memberID,
      poc: poc
    }, { headers: this.getHeaders() });
  }

  removeSingleParticipant(participantID: number) {
    return this.http.post(this.apiURL+'/removeSingleParticipant.php', {
      participantID: participantID
    }, { headers: this.getHeaders() });
  }

  addParticipant(eventID: number, memberID: number) {
    return this.http.post(this.apiURL+'/addActivityParticipant.php', {
      eventID: eventID,
      memberID: memberID
    }, { headers: this.getHeaders() });
  }

  getSrisevakTaskList() {
    return this.http.get(this.apiURL+'/getSrisevakTaskList.php', { headers: this.getHeaders() });
  }

  getLibraryDocuments() {
    return this.http.get(this.apiURL+'/getLibraryDocuments.php', { headers: this.getHeaders() });
  }

}
