import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { AlertController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private firstname: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private lastname: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private middlename: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private memberId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private fathername: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private dob: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private gender: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private gothram: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private contact: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private membershipDate: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private address1: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private address2: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private city: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private district: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private state: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private country: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private pincode: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private email: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private image: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private aadhar: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private qrstring: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private type: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private volunteer: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private token: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private device_id: BehaviorSubject<string> = new BehaviorSubject<string>('ioniclab');
  private membershipPoints: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private groups: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private push_token: BehaviorSubject<string> = new BehaviorSubject<string>(null);  

  constructor(private storage: Storage, private uniqueDeviceID: UniqueDeviceID, private appVersion: AppVersion, private alertCtrl: AlertController, private iab: InAppBrowser) {
  }

  setPushToken(push: string) {
    this.push_token.next(push);
  }

  getPushToken() {
    return this.push_token.value;
  }

  async setDeviceID() {
    try {
      const uuid = await this.uniqueDeviceID.get();
      console.log(uuid);
      this.device_id.next(uuid);
    }
    catch (error) {
      return console.log("DeviceID", error);
    }
  }

  getDeviceID() {
    return this.device_id.value;
  }  

  setData(data:any){
    this.memberId.next(data['MemberID']);
    this.firstname.next(data['FirstName']);
    this.lastname.next(data['LastName']);
    this.middlename.next(data['MiddleName']);
    this.fathername.next(data['FatherName']);
    this.dob.next(data['DateOfBirth']);
    this.gothram.next(data['Gothram']);
    this.gender.next(data['Gender']);
    this.contact.next(data['ContactNumber']);
    this.membershipDate.next(data['membershipDate']);
    this.address1.next(data['Address1']);
    this.address2.next(data['Address2']);
    this.city.next(data['City']);
    this.district.next(data['District']);
    this.state.next(data['State']);
    this.country.next(data['Country']);
    this.pincode.next(data['PinCode']);
    this.email.next(data['Email']);
    this.image.next(data['image']);
    this.aadhar.next(data['AadharCard']);
    this.qrstring.next(data['QRString']);
    this.type.next(data['Type']);
    this.volunteer.next(data['Volunteer']);
    this.token.next(data['token']);
    this.membershipPoints.next(data['MembershipPoints']);
    this.groups.next(data['Groups']);
  }

  getData() {
    return {
      MemberID: this.memberId.value,
      FirstName: this.firstname.value,
      MiddleName: this.middlename.value,
      LastName: this.lastname.value,
      FatherName: this.fathername.value,
      DateOfBirth: this.dob.value,
      Gothram: this.gothram.value,
      Gender: this.gender.value,
      ContactNumber: this.contact.value,
      MembershipDate: this.membershipDate.value,
      Address1: this.address1.value,
      Address2: this.address2.value,
      City: this.city.value,
      District: this.district.value,
      State: this.state.value,
      Country: this.country.value,
      PinCode: this.pincode.value,
      Email: this.email.value,
      image: this.image.value,
      AadharCard: this.aadhar.value,
      Type: this.type.value,
      Volunteer: this.volunteer.value,
      MembershipPoints: this.membershipPoints.value,
      Groups: this.groups.value, 
      token: this.token.value
    };
  }

  setSession(data:any){
    this.setData(data);
    this.storage.set('session', JSON.stringify(data));
  }

  destroySession(){
    this.storage.remove('session').then(() => {
      this.memberId.next(null);
      this.firstname.next(null);
      this.lastname.next(null);
      this.middlename.next(null);
      this.fathername.next(null);
      this.dob.next(null);
      this.gothram.next(null);
      this.gender.next(null);
      this.contact.next(null);
      this.membershipDate.next(null);
      this.address1.next(null);
      this.address2.next(null);
      this.city.next(null);
      this.district.next(null);
      this.state.next(null);
      this.country.next(null);
      this.pincode.next(null);
      this.email.next(null);
      this.image.next(null);
      this.aadhar.next(null);
      this.qrstring.next(null);
      this.type.next(null);
      this.volunteer.next(null);
      this.token.next(null);
      this.membershipPoints.next(0);
      this.storage.clear();
    });
  }

  getSessionData(){
    this.setDeviceID();
    this.storage.get('session').then((val) => {
      let data = JSON.parse(val);
      if (data != null)
      this.setData(data);
    });
  }

  getToken() {
    return this.token.value;
  }

  setToken(token: string) {
    this.token.next(token);
  }

  getMemberID() {
    return this.memberId.value;
  }

  getImageURL() {
    return this.image.value;
  }

  getFirstName() {
    return this.firstname.value;
  }

  getMiddleName() {
    return this.middlename.value;
  }

  getLastName() {
    return this.lastname.value;
  }

  getFatherName() {
    return this.fathername.value;
  }

  getGothram() {
    return this.gothram.value;
  }

  getContact() {
    return this.contact.value;
  }

  getEmail() {
    return this.email.value;
  }

  getAddress1() {
    return this.address1.value;
  }

  getAddress2() {
    return this.address2.value;
  }

  getCity() {
    return this.city.value;
  }

  getDistrict() {
    return this.district.value;
  }

  getState() {
    return this.state.value;
  }

  getPinCode() {
    return this.pincode.value;
  }

  getAadharNo() {
    return this.aadhar.value;
  }

  getQRString() {
    return this.qrstring.value;
  }

  getDOB() {
    return this.dob.value;
  }

  getGender() {
    return this.gender.value;
  }

  getType() {
    return this.type.value;
  }

  getGroups() {
    return this.groups.value;
  }

  isVolunteer() {
    if (this.volunteer.value == 'Yes'){
      return true;
    }else{
      return false;
    }
  }

  getVolunteer() {
    return this.volunteer.value;
  }

  setVolunteer(stat){
    this.volunteer.next(stat);
  }

  setImageURL(image) {
    this.image.next(image);
  }

  getMembershipDate() {
    return this.membershipDate.value;
  }

  getMembershipPoints() {
    return this.membershipPoints.value;
  }

  checkVersionUpdate(iOSVersion, androidVersion, isIOS, isAndroid) {
    this.appVersion.getVersionNumber().then(versionNo => {
      console.log(versionNo);
      if (isAndroid && versionNo != androidVersion) {
        this.showUpdatePopup('android');
      } else if (isIOS && versionNo != iOSVersion) {
        this.showUpdatePopup('iOS');
      }
    }).catch(err => {
      console.log(err);
    });    
  }

  async showUpdatePopup(platform){
    let link = "https://play.google.com/store/apps/details?id=org.kcdastrust.Gayatri&hl=en_IN";
    if (platform == 'iOS') {
      link = "https://apps.apple.com/gb/app/kcdt-gayatri/id1484951192";
    }
    const alert = await this.alertCtrl.create({
      header: 'New Update Available!',
      subHeader: 'Would you like to update to the latest version?',
      buttons: [
        {
          text: "No",
          role: 'cancel'
        },
        {
          text: 'Yes',
          handler: () => {
            this.iab.create(link, '_system', {location:'no',zoom:'no'});
          }
        }
      ]
    });
    await alert.present();
  }

}
