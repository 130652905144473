import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UtilsService } from './utils.service';
import { SessionService } from './session.service';
import { HttpService } from './http.service';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private utils: UtilsService, private session: SessionService, private router: Router, private http: HttpService, private plt: Platform) {  }

  delay(ms: number) {
      return new Promise( resolve => setTimeout(resolve, ms) );
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.session.getSessionData();
    await this.delay(1000);    
    let token = this.session.getToken();
    if (token != null && token.length == 128) {
      this.http.updateVersion();
      this.http.getUserData().subscribe(
        res => {
          console.log("UserData", res);          
          this.session.checkVersionUpdate(res[0].iOS, res[0].android, this.plt.is('ios'), this.plt.is('android'));
          this.session.setSession(res[0]);
        },
        err => {
          console.log(err);
          this.session.destroySession();
          this.router.navigateByUrl('/login');      
          this.utils.showToast('toast-warning', 'There was an inconsistency within your data. Please signin again to reset the configuration files.', 4000);
        }
      );
      this.router.navigateByUrl('/tabs');
      return false;
    }
    return true;
  }

}
